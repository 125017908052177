.container_register {
        position: relative;
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 40px 20px 60px 20px;
}

.container_register form {
        position: relative;
        display: flex;
        width: 100%;
        max-width: 500px;
        flex-direction: column;
        gap: 20px;
}

.container_register form span {
        display: flex;
        width: 100%;
        flex-direction: column;
        row-gap: 5px;
}


.container_register form span input,
.container_register form span select {

        width: 100%;
        height: 35px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.3);

}

.container_register form span:nth-child(1) {
        align-items: center;
        justify-content: center;
        text-align: center;
}

.container_register form span:nth-child(1)>strong {
        text-transform: uppercase;
        font-size: 1.4em;
}

.container_register form span:last-child {
        align-items: center;
        justify-content: center;
}

.container_register form span:last-child button {
        position: relative;
        min-width: 200px;
        height: 35px;
        max-width: 60%;
        background-image: linear-gradient(to right, #062564, #02478B);
        font-size: 1.1em;
        font-weight: bold;
        color: #FFFFFF;
        border: none;
        border-radius: 5px;
        transition: font-size .2s ease;
        cursor: pointer;
}

.container_register form span:last-child button:active {
        font-size: 1em;
}

.box_loader{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        column-gap: 10px;
}
.loader {
        border: 3px solid #FFFFFF;
        border-left-color: transparent;
        border-radius: 50%;
        border-left-color: transparent;
        width: auto;
        height: 70%;
        aspect-ratio: 1/1;
        animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
        0% {
                transform: rotate(0deg);
        }

        100% {
                transform: rotate(360deg);
        }
}