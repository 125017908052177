.container_products {
    position: relative;
    display: grid;
    width: 100%;
    min-height: 60vh;
    max-width: 1400px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
}

.container_products>h3 {
    grid-column: 1 / -1;
    text-align: center;
    font-size: 1.5em;
    padding: 30px 0;
    color: #062564;
    font-weight: bold;

}

.box_product {
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.box_product span:nth-child(1) {
    display: flex;
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    justify-content: center;
    align-items: center;
    overflow: hidden;

}

.box_product span:nth-child(1) img {
    width: auto;
    height: 100%;
    object-fit: contain;
    transition: transform .2s ease;

}

.box_product span:nth-child(1) svg {
    width: 65%;
    height: auto;
    transition: transform .2s ease;
}

.box_product span:nth-child(2) {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 10px;
    row-gap: 5px;
}

.box_product span:nth-child(2) h3 {
    width: 100%;
    font-size: 1.2em;
    color: #313131;
    font-weight: 400;
}

.box_product span:nth-child(2) p {
    font-size: 1.6em;
    font-weight: bold;
    color: #062564;
}

.box_product span:nth-child(2)>span {
    display: flex;
    width: 100%;
    height: auto;
    padding: 5px 0;
    align-items: center;
    justify-content: center;
}

.box_product span:nth-child(2)>span button {
    width: 60%;
    height: auto;
    padding: 7px 0;
    border: none;
    background-color: #062564;
    color: #FFFFFF;
    border-radius: 5px;
    font-size: .9em;
    font-weight: bold;
    text-transform: uppercase;
    opacity: 0;
    transition: opacity 0.2s ease;
    cursor: pointer;
}

.box_product:hover span:nth-child(2)>span button {
    opacity: 1;
}

.box_product:hover span:nth-child(1) img {
    transform: scale(1.1);
}

.box_product:hover span:nth-child(1) svg {
    transform: scale(1.1);
}