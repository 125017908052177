.container_orders {
        position: relative;
        width: calc(100% - var(--width-responsive));
        margin-left: var(--width-responsive);
        padding: 20px;
}

.container_orders > div {
        display: flex;
        width: 100%;
        height: auto;
        overflow-x: scroll;
        padding-top:20px ;
}

.box_order_table {
        width: 100%;
}

.box_order_table thead {
        display: grid;
        position: sticky;
        top: 0;
        width: 100%;
        min-width: 1300px;
        height: 40px;
        background-color: #02478B;
        color: #FFFFFF;
}

.box_order_table tbody tr,
.box_order_table thead tr {
        display: grid;
        grid-template-columns: 50px repeat(8,1fr);
        place-content: center;
}

.box_order_table thead tr th {
        justify-content: center;
        padding: 0 20px;
}

.box_order_table tbody tr {
        padding: 10px 0;
}

.box_order_table tbody tr td {
        font-weight: normal;
        display: flex;
        gap: 10px;
        height: auto;
        align-items: center;
        justify-content: center;
}

.box_order_table tbody tr td:last-child {
        justify-content: flex-start;
        flex-direction: column;
}

.box_order_table tbody tr td:nth-last-child(3) {
        white-space: nowrap;
}

.box_order_table tbody tr td:nth-last-child(3) p{
        padding: 3px 10px;
        border-radius: 5px;
        font-weight: bold;
        color: #FFFFFF;
}

.box_order_table tbody tr td:last-child button{
        width: 80px;
        padding: 2px 0;
        border: 1px solid rgba(0,0,0,0.3);
        border-radius: 5px;
        cursor: pointer;
}

tbody tr:nth-child(even) {
        background-color: #eaeaea;
}