.container_login{
    position: fixed;
    display: flex;
    width:300px;
    min-height:200px ;
    height: auto;
    z-index: 1200;
    padding: 20px;
    right: 0;
    background:white;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    transition: opacity 0.2 ease;
    flex-direction: column;
}
.container_login > span{
    width: 100%;
    height: 20px;
    padding-left: 20px;
}
.container_login form{
    display:flex;
    width: 100%;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
}

.container_login form > h3{
    position: relative;
    width: 100%;
    text-align: center;
    padding:10px;
}
.container_login form input{
    width: 100%;
    height: 30px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding-left:10px ;
}

.container_login form > span{
    display: flex;
    position: relative;
    width: 100%;
    justify-content: space-between;
}
.container_login form span > button{
    position: relative;
    width:45%;
    text-align: center;
    padding: 5px 10px;
    background-image: linear-gradient(to right, #062564, #02478B);
    color: #FFFFFF;
    font-weight: bold;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
}
