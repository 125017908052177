.title_header {
    position: sticky;
    display: flex;
    width: 100%;
    height: 30px;
    column-gap: 20px;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    top: 0;
    background-image: linear-gradient(to right, #062564, #02478B);
    z-index: 100;
}

.title_header p {
    font-size: .7em;
}

header {
    position: fixed;
    display: flex;
    width: 100%;
    height: 50px;
    box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.2);
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
    bottom: 0;
    background-color: #FFFFFF;
    z-index: 2000;
}

header div:nth-child(2) {
    position: absolute;
    display: flex;
    width: 100%;
    height: auto;
    bottom: 50px;
    left: 0;
    flex-direction: column;
    background-color: #FFFFFF;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease;
    border-top: 1px solid #02478B90;
}

header .active_menu {
    opacity: 1 !important;
    visibility: visible !important;
}

header div:nth-child(2) a {
    display: flex;
    width: 150px;
    height: 35px;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 5px;
}

header div:nth-child(2) a img {
    display: flex;
    height: 100%;
    width: auto;
    object-fit: contain;
}

header nav {
    display: flex;
    width: 100%;

}

header nav ul {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

header nav ul li {
    display: flex;
    width: 100%;
    list-style: none;
    justify-content: center;
}

header nav ul li a {
    position: relative;
    display: flex;
    width: 140px;
    height: 30px;
    text-decoration: none;
    color: #062564;
    text-align: center;
    justify-content: center;
    align-items: center;
}

header div {
    display: flex;
    width: 20%;
    align-items: center;
    justify-content: flex-end;
    column-gap: 10px;
}

header button {
    display: flex;
    border: none;
    background: none;
    align-items: center;
    column-gap: 10px;
}

header button:nth-child(1) {
    align-items: flex-start;
}

header button p {
    color: #062564;
    display: none;
}

header button:nth-child(3) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

header button:nth-child(4) p {
    display: flex;
}

header button span {
    border: none;
    display: grid;
    width: 35px;
    height: 35px;
    place-content: center;
    cursor: pointer;
    border-radius: 50%;
    background-color: transparent;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

header button span b {
    font-size: 1.1em;
    color: #062564;
    font-weight: bold;
}

header button span svg {
    width: 1.2em;
    height: 1.2em;
    fill: #062564;
}

@media screen and (min-width:1024px) {

    .title_header p {
        font-size: .9em;
    }

    header {
        position: sticky;
        display: flex;
        width: 100%;
        height: 50px;
        top: 30px;
        column-gap: 10px;
    }

    header button:nth-child(1) {
        display: none;
    }

    header button:nth-child(3) {
        position: relative;
        left: initial;
        transform: none;
    }

    header div:nth-child(2) {
        position: relative;
        width: 100%;
        bottom: 0;
        opacity: 1;
        visibility: visible;
        flex-direction: row;
        background-color: transparent;
        border-top: none;
    }

    header div:nth-child(2)>a {
        width: 100px;
    }

    header button:nth-child(3) a {
        display: flex;
        width: 100%;
        height: 50px;
    }

    header button p {
        display: flex;
        white-space: nowrap;
    }

    header nav ul {
        flex-direction: row;
        text-align: center;
    }

    header nav ul li {
        display: grid;
        width: 33%;
        place-content: center;
    }

}