.container_slider {
        position: relative;
        width: 100%;
        height: 400px;
        background-image: linear-gradient(#ffffff 10%, transparent 30%), url(../img/slider.png);
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
}

.box_carrousel {
        display: flex;
        width: 100%;
        height: 100%;
        place-content: center;
        color: white;
        z-index: 1000;
        background-color: transparent;
        flex-direction: column-reverse;
}

.box_carrousel div:first-child {
        display: flex;
        width: 100%;
        height: 100%;
        place-content: center;
        place-items: center;
        text-align: center;
        align-items: flex-start;
        row-gap: 10px;

}

.box_carrousel div:first-child>span {
        display: flex;
        width: min-content;
        text-align: left;
        padding: 20px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 10px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(7px);
        -webkit-backdrop-filter: blur(7px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        color: #062564;
        gap: 10px;
        flex-direction: column;

}

.box_carrousel div:first-child>span>h1 {
        text-transform: uppercase;
        font-size: 2em;
        border-bottom: 1px solid #062564;
}


.box_carrousel div:last-child {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        place-content: center;
        transition: z-index .2s ease;
}

.box_carrousel div:last-child img {
        position: absolute;
        width: 60%;
        height: 100%;
        object-fit: contain;
}

.box_left,
.box_right {
        position: absolute;
        display: flex;
        height: 100%;
        width: 50px;
        background-color: transparent;
        align-items: center;
        justify-content: center;
        z-index: 1100;
        top: 0;

}

.box_left button,
.box_right button {
        display: grid;
        width: 40px;
        height: 40px;
        background-color: transparent;
        place-content: center;
        border: none;
        cursor: pointer;

}

.box_left button svg,
.box_right button svg {
        width: 2em;
        height: 2em;
        fill: #FFFFFF;
        opacity: 1;
        transition: opacity .2s ease;
        z-index: -1;
}

.box_left:hover button svg,
.box_right:hover button svg {
        opacity: 1;
}

.box_right {
        right: 0;
}

.box_left {
        left: 0;
}

.box_waves {
        width: 100%;
        position: absolute;
        bottom: -10px;
        z-index: 1;
}

@media screen and (min-width:768px) {
        .box_carrousel {
                flex-direction: row;
        }

        .box_carrousel div:first-child {
                width: 50%;
                flex-direction: column;
        }

        .box_carrousel div:first-child {
                padding-left: 60px;

        }

        .box_carrousel div:first-child img {
                padding-bottom: 40px;
        }

        .box_carrousel div:last-child {
                width: 50%;
        }

        .box_carrousel div:last-child img {
                position: absolute;
                width: 90%;
                object-fit: contain;
        }
}

@media screen and (min-width:1024px) {
        .box_carrousel div:last-child img {
                width: 80%;
                top: -50px;
        }
}

@media screen and (min-width:1330px) {
        .box_carrousel div:last-child img {
                width: 100%;
                max-height: 100%;
                top: -50px;
        }
}