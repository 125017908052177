.container_client {
        width: calc(100% - var(--width-responsive));
        margin-left: var(--width-responsive);
        padding: 20px;
        padding-bottom: 60px;
}

.container_client form {
        width: 100%;
        height: auto;
}

.box_cart {
        display: flex;
        width: 100%;
        flex-direction: column;
}

.box_cart div:first-child {
        display: flex;
        width: 100%;
        align-items: center;
        row-gap: 10px;
        box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
        padding: 10px;
        flex-direction: column;
}

.box_cart div:first-child span:first-child {
        display: flex;
        width: 60%;
        aspect-ratio: 1 / 1;
        align-items: center;
        justify-content: center;
}

.box_cart div:first-child span:first-child svg {
        width: 5em;
        height: 5em;
}

.box_cart div:first-child span:last-child {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
}

.box_cart div:first-child span:last-child p {
        position: relative;
        width: 100%;
        text-align: left;
        padding-left: 20px;
}

.box_cart div:last-child {
        display: flex;
        width: 100%;
        padding-left: 20px;
        padding: 20px 0 0;
        row-gap: 10px;
        flex-direction: column;
}

.box_cart div:last-child>p {
        position: relative;
        text-align: center;
        text-transform: uppercase;
        padding-bottom: 10px;
        font-weight: bold;
}

.box_cart div:last-child span {
        display: flex;
        width: 100%;
        flex-direction: column;
        row-gap: 5px;
}

.box_cart div:last-child span input {
        width: 100%;
        height: 35px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.2);
}

.container_client form>span:last-child {
        display: grid;
        width: 100%;
        place-content: center;
        padding: 20px;
}

.container_client form>span:last-child>button {
        width: auto;
        padding: 5px 20px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        font-size: 1em;
        font-weight: bold;
}


@media screen and (min-width:768px) {

        .box_cart div:first-child {
                flex-direction: initial;
        }

        .box_cart div:first-child span:first-child {
                width: 20%;
        }

        .box_cart div:first-child span:last-child {
                width: 80%;
        }

        .box_cart div:first-child>span svg {
                width: 3em;
                height: 3em;
        }

        .box_cart div:last-child {
                flex-direction: initial;
                flex-wrap: wrap;
                gap: 20px;
        }
        .box_cart div:last-child p{
                width: 100%;
        }

        .box_cart div:last-child span {
                width: calc(50% - 20px);
        }

}

@media screen and (min-width:1330px) {

        :root {
                --width-responsive: 150px;
        }

        .box_cart{
                flex-direction: row;
        }
        .box_cart div:first-child{
                width: 20%;
                flex-direction:column ;
        }

        .box_cart div:first-child span:last-child{
                width: 100%;
        }

        .box_cart div:first-child span:last-child p{
                padding-left: 0;
        }
        .box_cart div:last-child{
                padding: 20px;
        }
}