@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

body {
  font-family:'Ubuntu', sans-serif;;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}