.container_toast{
    position: fixed;
    display: flex;
    width:300px;
    height: auto;
    top: 80px;
    right: 0;
    z-index: 9999999;
    background-color: #FFFFFF;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    overflow: hidden;
}

.container_toast > span:nth-child(1){
    display: grid;
    width: 45px;
    height: 45px;
    place-content: center;
    background-color:red;
}

.container_toast > span:nth-child(1) svg{
    fill: #FFFFFF;
    width:1.7em;
    height: 1.7em;
}

.container_toast > span:nth-child(2){
    display: flex;
    width: calc(100% - 45px);
    padding:5px 10px;
    align-items: center;
}

.container_toast > span:nth-child(2) p{
    font-size: .9em;
}