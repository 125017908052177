.container_shopping_cart {
    position: fixed;
    display: flex;
    width: 300px;
    height: calc(100% - 80px);
    z-index: 1000;
    flex-direction: column;
    background-color: white;
    right: 0;
    bottom: 50px;
    box-shadow: -1px 3px 7px 1px rgba(0, 0, 0, 0.17);
    padding: 0 10px;
    row-gap: 10px;
}

.container_shopping_cart span:nth-child(1) {
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    column-gap: 40px;
}

.container_shopping_cart span:nth-child(1) button {
    display: grid;
    width: 30px;
    height: 30px;
    place-content: center;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: rgb(245, 245, 245);
}

.container_shopping_cart span:nth-child(1) button svg {
    width: 1.3em;
    height: 1.3em;
}

.container_shopping_cart span:nth-child(1) p {
    font-weight: bold;
    font-size: 1em;
    text-transform: uppercase;
}

.container_shopping_cart span:nth-child(3) {
    display: grid;
    width: 100%;
    place-content: center;
    padding: 5px 20px;
}

.box_shopping_cart {
    display: flex;
    width: 100%;
    height: calc(100% - 190px);
    row-gap: 20px;
    flex-direction: column;
    overflow-y: scroll;
    padding-right: 10px;
}

.card_container {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 130px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    flex-wrap: wrap;
}

.card_container>button {
    display: grid;
    position: absolute;
    width: 25px;
    height: 25px;
    place-content: center;
    border-radius: 50%;
    top: 5px;
    right: 5px;
    border: 1px solid rgba(0, 0, 0, 0.17);
    font-weight: bold;
    font-size: 1.1em;
}

.card_container > span:nth-child(1) {
    width: 35%;
    height: auto;
    aspect-ratio: 1 / 1;
    padding: 0 ;
    overflow: hidden;

}

.card_container > span:nth-child(1) svg {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
}

.card_container_img_lazy {
    width: 100%;
    height: auto;
}

.card_container_img_lazy img {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    object-fit: contain;
}

.card_container > span:nth-child(2) {
    display: flex;
    width: 65%;
    flex-direction: column;
    row-gap: 10px;
    padding-right: 30px;
    padding-top: 10px;
}

.card_container > span:nth-child(2) h3 {
    font-size: .9em;
}

.card_container>span:nth-child(3) {
    width: 100%;
    display: flex;
    height: auto;
    padding: 0;
    text-align: left;
    place-content: initial;
}

.card_container > span:nth-child(3) strong {
    padding: 10px;
    font-size: 1.2em;
}

.container_shopping_cart > span:nth-child(4),
.container_shopping_cart > span:last-child {
    width: 100% !important;
}

.container_shopping_cart > span:nth-child(4) button,
.container_shopping_cart > span:last-child button {
    width: 100% !important;
    padding: 5px 30px;
    background-image: linear-gradient(to right, #062564, #02478B);
    color: #FFFFFF;
    border: none;
    cursor: pointer;
}

.container_shopping_cart span:nth-child(4) button {
    background-image: linear-gradient(to right, #4f545e, #212529);
}

::-webkit-scrollbar {
    width: 5px;
    /* Ancho del scrollbar */
}

::-webkit-scrollbar-track {
    background: #fff;
    /* Color del fondo del scrollbar */
}

::-webkit-scrollbar-thumb {
    background: #062564;
    /* Color del thumb del scrollbar */
}

::-webkit-scrollbar-thumb:hover {
    background: #02478B;
    /* Color del thumb del scrollbar al pasar el mouse por encima */
}

@media screen and (min-width:1024px) {
    .container_shopping_cart {
        bottom: 0;
    }
}