.container_global_details {
        display: flex;
        width: 100%;
        height: auto;
        padding: 20px;
        flex-wrap: wrap;
        gap: 20px;
}

.container_table_pay {
        width: 100%;
        position: relative;
        overflow-x: scroll;
}

.container_table_pay table {
        width: 100%;
        min-width: 900px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        padding: 20px 0;
}

.container_table_pay table thead {
        background-image: linear-gradient(to right, #062564, #02478B);
        color: #FFFFFF;
}

.container_table_pay table tfoot tr:last-child {
        font-weight: bold;

}

.container_table_pay table thead tr,
.container_table_pay table tbody tr,
.container_table_pay table tfoot tr {
        width: 100%;
        display: grid;
        grid-template-columns: 150px 40% repeat(2, 1fr);
        place-content: center;
        padding: 5px;
}

.container_table_pay table tbody tr td:nth-child(3) {
        text-align: center;
}


.container_table_pay table tbody tr:nth-child(even) {
        background-color: #eaeaea;
}

.box_content_payment,
.container_payment_cart {
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.4);
        display: flex;
        border-radius: 5px;
}

.box_content_payment {
        flex-direction: column;
        row-gap: 10px;
        padding: 10px 0;
}
.container_payment_cart{
        align-items: center;
        justify-content: center;
        padding: 20px;
}

.container_payment_cart button {
        padding: 5px 20px;
        font-size: 1.2em;
        font-weight: bold;
}