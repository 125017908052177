.container_payment_getnet {
        width: 100%;
        min-height: 70vh;
        display: flex;
        padding: 20px;
        align-items: center;
        justify-content: center;
}

.container_payment_getnet div {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px;
        max-width: 700px;
        border-radius: 10px;
        row-gap: 10px;
}

.box_shadow {
        box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
}

.box_shadow_error {
        box-shadow: 1px 1px 10px 1px rgba(239, 33, 33, 0.2);
}

.box_shadow_error p:nth-child(2) {
        color: red;
}



.container_payment_getnet div p {
        font-size: 1rem;
}

@media screen and (min-width:768px) {
        .container_payment_getnet div p {
                font-size: 1.2rem;
        }
}