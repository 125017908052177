:root {
        --width-responsive: 50px;
}

.container_about_us {
        display: flex;
        width: 100%;
        height: auto;
        color: #1b6ac599;
        padding: 20px;
        flex-direction: column;
        align-items: center;
}

.container_logo {
        display: flex;
        width: 100%;
        justify-content: center;
}

.container_logo img {
        width: 100%;
        max-width: 300px;
        height: auto;
        object-fit: contain;
}

.container_content {
        display: flex;
        width: 100%;
        justify-content: center;
        flex-direction: column;
        max-width: 1400px;
        gap: 20px;
}

.container_content>p {
        width: 100%;
        max-width: 1400px;
        text-align: justify;
        font-size: 1.2rem;
        line-height: 1.5rem;
        margin: 0 auto;
        color: #062564;

}

.box_list {
        position: relative;
        display: grid;
        width: 100%;
        max-width: 1400px;
        color: #062564;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
}

.box_list span {
        display: flex;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.3);
        padding: 10px;
        flex-direction: column;
        row-gap: 15px;
        border-radius: 5px;
        background-color: #f0f0f0;
}

.box_list span strong {
        font-size: 1.2rem;
}

.box_footer_about {
        display: grid;
        width: 100%;
        max-width: 1400px;
        place-content: center;
        padding: 30px 0;

}

.box_footer_about p {
        font-size: 1.2rem;
        color: #062564;
        text-align: center;
        font-style: italic;
        font-weight: bold;
}