.container_toast_preview_product {

        --default-height: 40px;
        position: fixed;
        top: 40px;
        right: 10px;
        width: 300px;
        height: 150px;
        z-index: 1000;
        background-color: #ffffff;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        overflow: hidden;
}

.container_toast_preview_product span:first-child {
        display: flex;
        width: 100%;
        height: var(--default-height);
        background-image: linear-gradient(to right, #062564, #02478B);
        color: #ffffff;
        align-items: center;
        justify-content: center;
}

.container_toast_preview_product>span:first-child>p {
        font-size: 1em;
        font-weight: bold;
}

.container_toast_preview_product>span:last-child {
        position: relative;
        display: flex;
        width: 100%;
        height: calc(100% - var(--default-height));
        flex-wrap: wrap;
        padding: 10px;
}

.container_toast_preview_product span:last-child img {
        width: 40%;
        height: 100%;
        object-fit: contain;

}

.container_toast_preview_product span:last-child>svg {
        width: 40%;
        height: 100%;
        object-fit: contain;
        fill: #062564;
}

.container_toast_preview_product span:last-child>span {
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;
}

.container_toast_preview_product span:last-child span h3 {
        font-size: 1.1em;
        font-weight: bold;
        margin: 0;
        padding: 0;
}

@media screen and (min-width:1024px) {
        .container_toast_preview_product {
                top: 90px;
        }
}