.container_my_data {
        position: fixed;
        display: flex;
        width: 50px;
        box-shadow: 3px 1px 5px rgba(0, 0, 0, 0.2);
        height: calc(100% - 80px);
}

.container_my_data ul {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 5px;
        row-gap: 5px;
}

.container_my_data ul a {
        display: flex;
        width: 100%;
        aspect-ratio: 1 / 1;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        row-gap: 5px;
        text-decoration: none;
        color: rgba(0, 0, 0, 0.5);
}

.container_my_data ul a p {
        display: none;
        text-align: center;
}

.container_my_data ul a span svg {
        width: 1.2em;
        height: 1.2em;
}

@media screen and (min-width:1330px) {

        .container_my_data {
                width: 150px;
        }
        .container_my_data ul{
                padding: 20px;
        }

        .container_my_data ul a p{
                display: flex;
        }
        .container_my_data ul a span svg {
                width: 1.7em;
                height: 1.7em;
        }

}