.container_contact{
        display: flex;
        width: 100%;
        height: auto;
        padding: 20px;
        justify-content: center;
}
.container_contact form{
        position: relative;
        display: flex;
        width: 100%;
        max-width: 600px;
        flex-direction: column;
        row-gap: 20px;
}
.container_contact form span{
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
}
.container_contact form span input{
        width: 100%;
        height: 35px;
        border-radius: 5px;
        border: 1px solid rgba(0,0,0,0.2);
}
.container_contact form span textarea{
        max-width: 100%;
        min-width: 100px;
        max-height: 100px;
        border-radius: 5px;
        border: 1px solid rgba(0,0,0,0.2);

}
.container_contact form span:last-child{
        display: grid;
        padding: 20px;
        place-content: center;
}
.container_contact form span:last-child button{
        padding: 5px 40px;
        border-radius: 5px;
        border: 1px solid rgba(0,0,0,0.2);

}