.container_footer {
        position: relative;
        text-align: center;
        color: rgb(91, 52, 52);
        padding-bottom: 50px;
}

.waves {
        position: relative;
        width: 100%;
        height: 15vh;
        margin-bottom: -7px;
        /*Fix for safari gap*/
        min-height: 40px;
        max-height: 40px;
        color: rgba(27, 106, 197, 0.532);
}


/* Animation */

.parallax>use {
        animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax>use:nth-child(1) {
        animation-delay: -2s;
        animation-duration: 7s;
}

.parallax>use:nth-child(2) {
        animation-delay: -3s;
        animation-duration: 10s;
}

.parallax>use:nth-child(3) {
        animation-delay: -4s;
        animation-duration: 13s;
}

.parallax>use:nth-child(4) {
        animation-delay: -5s;
        animation-duration: 20s;
}

@keyframes move-forever {
        0% {
                transform: translate3d(-90px, 0, 0);
        }

        100% {
                transform: translate3d(85px, 0, 0);
        }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
        .waves {
                height: 40px;
                min-height: 40px;
        }

        .content {
                height: 30vh;
        }

        h1 {
                font-size: 24px;
        }
}



.box_content_footer {
        display: flex;
        width: 100%;
        background-color: rgba(27, 106, 197, 1);
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding-bottom: 20px;
}

.box_content_footer span:first-child {
        display: flex;
        width: 100%;
        padding: 10px 0;
        color: white;
        font-size: 1.1em;
        align-items: center;
        justify-content: center;
        column-gap: 20px;
}

.box_content_footer span:first-child a {
        color: #FFFFFF;
        text-decoration: none;
        font-weight: 600;
}

.box_content_footer span:last-child {
        width: 100%;
        padding: 10px 0;
        color: #FFFFFF;
        font-size: .9em;
}

.box_content_footer span:last-child a {
        color: white;
        text-decoration: none;
        font-weight: 600;
}

.box_content_footer span:last-child p svg {
        fill: red
}

@media screen and (min-width:1024px) {
        .container_footer {
                padding-bottom: 0;
        }

}