.container_reset {
        display: flex;
        width: calc(100% - var(--width-responsive));
        margin-left: var(--width-responsive);
        padding: 10px;
        flex-wrap: wrap;
        gap: 20px;
}

.container_note {
        display: flex;
        width: 100%;
        flex-direction: column;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        padding: 10px;
        border-radius: 5px;
}

.container_note h2 {
        padding-bottom: 10px;
}

.container_note strong {
        color: red;
}

.container_note ul {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        padding-left: 20px;
}

.container_change_password {
        width: 100%;
        display: flex;
        flex-direction: column;

}

.container_change_password form {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 10px;
        align-items: center;
}

.container_change_password form h4 {
        text-align: center;
}

.container_change_password form input {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        outline: none;
}

.container_change_password form button {
        width: min-content;
        padding: 3px 30px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        outline: none;  
        background-image: linear-gradient(to right, #062564, #02478B);
        color: #fff;
        cursor: pointer;
        font-weight: bold;
}

@media screen and (min-width:768px) {
        .container_reset {
                padding: 20px;
        }

        .container_note {
                width: calc(50% - 20px);
        }

        .container_change_password {
                width: calc(50% - 20px);
        }

}