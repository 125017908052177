.container_details_orders {
        display: flex;
        width: calc(100% - var(--width-responsive));
        margin-left: var(--width-responsive);
        padding: 20px;
        overflow-x: scroll;
}

.box_table_detail {
        position: relative;
        width: 100%;
        min-width: 900px;
}

.box_table_detail caption {
        padding: 10px;
        font-size: 1em;
        text-transform: uppercase;
        background-image: linear-gradient(to right, #062564, #02478B);
        color: #FFFFFF;
        font-weight: bold;
}

.box_table_detail tbody tr,
.box_table_detail thead tr {
        display: grid;
        grid-template-columns: 50px 30% repeat(5, 1fr) 150px;
        place-content: center;
}

.box_table_detail thead {
        background-color: #006dd9;
        color: #FFFFFF;
        font-weight: 100;
}

.box_table_detail thead tr th {
        justify-content: center;
}

.box_table_detail tbody tr {
        padding: 10px 0;
}

.box_table_detail tbody tr td {
        font-weight: normal;
        display: flex;
        gap: 10px;
        height: auto;
        align-items: center;
        justify-content: center;
}

.box_table_detail tbody tr td form {
        display: flex;
        width: 100%;
        column-gap: 20px;
        justify-content: center;
        align-items: center;
        text-align: center;
        
}

.box_table_detail tbody tr td form input{
        width: 50px;
}

.box_table_detail tbody tr td form>button {
        width: 50px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 3px;
        font-weight: bold;
}

tbody tr:nth-child(even) {
        background-color: #eaeaea;
}