.container_home{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.container_logo{
    display: flex;
    width: 100%;
    place-content: center;
}

.container_logo img{
    width: 20%;
    height: auto;
    object-fit: contain;
}