.container_cart {
        position: relative;
        display: flex;
        width: 100%;
        padding-bottom: 70px;
        justify-content: center;
        padding-top: 20px;
}

.container_form {
        position: relative;
        display: flex;
        width: 100%;
        height: auto;
        flex-direction: column;
}

.box_inputs,
.box_shopping_cart {
        display: flex;
        width: 100%;
        flex-direction: column;
        row-gap: 5px;
        padding: 20px;
        padding-top: 0;
}

.box_inputs {
        padding-bottom: 30px;
}

.box_inputs span:first-child h3 {
        text-align: center;
        padding: 20px 0;
}

.box_inputs span {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 10px;
}

.box_inputs span:nth-child(1) {
        text-align: center;

}

.box_inputs span input,
.box_inputs span select {
        width: 100%;
        height: 35px;
        border: 1px solid rgba(0, 0, 0, .2);
        border-radius: 5px;
}

.box_shopping_cart {}

.box_shopping_cart>h3 {
        text-align: center;
        padding: 20px 0;
}

.box_shopping_cart>div {
        display: flex;
        width: 100%;
        flex-direction: column;
}

.box_resume_cart {
        display: flex;
        width: 100%;
        column-gap: 10px;
        height: 70px;
        align-items: center;
        justify-content: space-around;
}

.box_resume_cart span:nth-child(1) {
        width: 60px;
}

.box_resume_cart span:nth-child(2) {
        width: calc(100% - 160px);
}

.box_resume_cart span:nth-child(3) {
        width: 100px;
        text-align: end;
}

.box_method_payments {
        display: flex;
        width: 100%;
        font-size: 1.2em;
        row-gap: 10px;
        flex-direction: column;
        padding: 20px 0;
}

.box_method_payments>p {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 10px;
        font-size: 1.1em;
        font-weight: bold;
}

.box_method_payments span {
        display: flex;
        width: 100%;
        height: 50px;
        border: 1px solid rgba(0, 0, 0, .2);
        border-radius: 5px;
        cursor: pointer;
}

.box_method_payments span input {
        display: none;
}

.box_method_payments span label {
        position: relative;
}

.box_method_payments span input:checked+label {
        background-color: rgba(0, 0, 0, .07);
        box-shadow: 1px 1px 4px rgba(0, 0, 0, .3);
}

.box_method_payments span input:checked+label::before {
        content: "✔";
        display: grid;
        position: absolute;
        top: 10px;
        right: 10px;
        height: 60%;
        aspect-ratio: 1 / 1;
        background-color: green;
        border-radius: 50%;
        color: #FFFFFF;
        font-size: 1em;
        place-content: center;
}

.box_method_payments span label {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: flex-start;
}

.box_method_payments span label img {
        width: auto;
        height: 100%;
        object-fit: contain;
        padding: 5px;
}

.box_total {
        display: grid;
        width: 100%;
        place-content: center;
        padding: 20px;
        font-size: 1.3em;
}

.box_shopping_cart>div>span:nth-child(4) {
        display: flex;
        padding: 10px;
        gap: 10px;
        flex-wrap: wrap;
        background-color: rgba(0, 0, 0, .07);
}

.box_shopping_cart>div>span:nth-child(4)>p {
        position: relative;
        width: 100%;
        text-align: left;
}

.box_shopping_cart>div>span:last-child {
        position: relative;
        display: grid;
        width: 100%;
        padding: 10px;
        place-content: center;
        border-top: 1px solid rgba(0, 0, 0, .2);

}

.box_shopping_cart>div>span:last-child button {
        position: relative;
        min-width: 150px;
        max-width: 200px;
        height: 35px;
        background-image: linear-gradient(to right, #062564, #02478B);
        border: none;
        color: #FFFFFF;

}

.loading {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
        pointer-events: visiblePainted;
        flex-direction: column;
        row-gap: 30px;
}

.loading span {
        --clr: #19a3d9;
        width: 50px;
        height: 50px;
        position: relative;
}

.loading p {
        font-size: 1.1em;
        font-weight: bold;
        color: #FFFFFF;
        text-align: center;
}

.loading span:before,
.loading span:after {
        content: "";
        position: absolute;
        top: -10px;
        left: -10px;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        border: 10px solid transparent;
        border-top-color: var(--clr);
}

.loading span:before {
        z-index: 100;
        animation: spin 1s infinite;
}

.loading span:after {
        border: 10px solid rgba(255,255,255, .2);
}

@keyframes spin {
        0% {
                -webkit-transform: rotate(0deg);
                -ms-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
        }

        100% {
                -webkit-transform: rotate(360deg);
                -ms-transform: rotate(360deg);
                -o-transform: rotate(360deg);
                transform: rotate(360deg);
        }
}

@media screen and (min-width:1024px) {
        .container_cart {
                max-width: 1400px;
                margin: 0 auto;
        }

        .box_shopping_cart {

                border-left: 1px solid rgba(0, 0, 0, .2);
        }

        .container_form {
                flex-direction: row;
        }
}