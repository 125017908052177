.container_cart_pay {
        position: fixed;
        display: flex;
        width: 100%;
        min-height: 100vh;
        background-color: #e6e6e6e4;
        top: 0;
        right: 0;
        z-index: 1000000;
        align-items: center;
        justify-content: center;
        padding: 20px;
}

.box_credit_cart {
        position: relative;
        display: flex;
        width: 100%;
        max-width: 600px;
        aspect-ratio: 2 / 1.2;
        border-radius: 10px;
        border: 2px solid red;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #FFFFFF;
}

.box_credit_cart>button {
        position: absolute;
        width: 30px;
        aspect-ratio: 1 / 1;
        top: -40px;
        right: 0;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.3);

}

.box_credit_cart h3 {
        font-size: 2em;
        text-transform: uppercase;
        font-weight: bold;
        color: #595959;
}

.box_credit_cart div {
        width: 100%;
        display: flex;
        padding: 10px;
}

.box_credit_cart div form {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        padding: 5px;
}

.box_credit_cart div form label {
        padding: 5px;
        font-weight: bold;
        font-size: 1.4em;
}

.box_credit_cart div form button {
        padding: 5px 10px;
        font-weight: bold;
        margin: 20px;
}