.container_recover {
        display: flex;
        width: 100%;
        height: calc(100vh - 216px);
        justify-content: center;
        align-items: center;
        flex-direction: column;
}

.box_form_recover {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        padding: 20px;
        border-radius: 10px;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        align-items: center;
}

.box_form_recover input {
        width: 100%;
        height: 35px;
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #ccc;
}

.box_form_recover button {
        width: min-content;
        font-size: 1rem;
        padding: 5px 30px;
        border-radius: 5px;
        border: none;
        background-color: #062564;
        color: #FFFFFF;
        font-weight: bold;
}

.container_recover span:last-child {
        width: 100%;
        max-width: 400px;
        text-align: right;
        padding: 5px 0;
}

.container_recover span:last-child button {
        background-color: transparent;
        border: none;
        color: #062564;
        font-size: 0.9em;
        cursor: pointer;
}

.container_change_password {
        display: flex;
        width: 100%;
        height: calc(100vh - 216px);
        justify-content: center;
        align-items: center;
        flex-direction: column;
}

.container_change_password form {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        padding: 20px;
        border-radius: 10px;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        align-items: center;
}

.container_change_password form h3 {
        padding-bottom: 20px;
}

.container_change_password form input {
        width: 100%;
        height: 35px;
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #ccc;

}

.container_change_password form button {
        width: min-content;
        font-size: 1rem;
        padding: 5px 30px;
        border-radius: 5px;
        border: none;
        background-color: #062564;
        color: #FFFFFF;
        font-weight: bold;
}